import * as React from 'react';

// @mui
import {Container} from "@mui/material";
// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';
import { NominatorDetail } from "../sections";


// ----------------------------------------------------------------------

export default function NominationPage({nominatorId=undefined}) {
    const { themeStretch } = useSettings();

  return (
      <DashboardLayout>
          <Page title="Nomination">
              <Container maxWidth={themeStretch ? false : 'xl'}>
                <NominatorDetail searchAccountInit={nominatorId}/>
              </Container>
          </Page>
      </DashboardLayout>
    );
}
